import React, { useState } from "react";
import "./Home.css"; // Import the CSS file

import business1 from "../Assets/business1.png";
import business2 from "../Assets/business2.png";
import business3 from "../Assets/business3.png";
import business4 from "../Assets/business4.png";
import business5 from "../Assets/business5.png";
import business6 from "../Assets/business6.png";

import project1 from "../Assets/project1.png";
// import serviceCard from "../Assets/service-card.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonials from "../Testimonials/Testimonials";
import TechScroller from "../TechScroller/TechScroller";

import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase"; // Import the Firestore instance

import vector1 from "../Assets/vector1.png";
import vector2 from "../Assets/vector2.png";
import vector3 from "../Assets/vector3.png";
import vector4 from "../Assets/vector4.png";
import vector5 from "../Assets/vector5.png";
import vector6 from "../Assets/vector6.png";

import fitness from "../Assets/fitness.png";
import jobIt from "../Assets/jobIT.png";
import trip from "../Assets/tripGuide.png";
import ecom from "../Assets/ecomerce.png";
import techDom from "../Assets/socialMedia.png";
// import Header from "../Header/Header";
import ClientScroller from "../ClientScroller/ClientScroller";
import ImapctSection from "../ImpactSection/ImapctSection";

const projects = [
  {
    title: "KAYAK(Car Rent)",
    description:
      "A car rental platform that offers an easy way to book rental vehicles across various locations. The platform provides a user-friendly interface for browsing available cars, comparing prices, and making reservations.",
    technologies: ["React", "Node.js", "MongoDB", "AWS"],
    image: project1, // Replace this with the actual image import or path
  },
  {
    title: "MENTRO(Job IT)",
    description:
      "A job-seeking platform that connects job seekers with IT companies. The platform provides job listings, a resume builder, and application tracking, catering to both applicants and recruiters.",
    technologies: ["Angular", "Express.js", "MySQL", "GCP"],
    image: jobIt, // Replace this with the actual image import or path
  },
  {
    title: "TripGuide",
    description:
      "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars. It offers curated recommendations for popular destinations, travel itineraries, and more.",
    technologies: ["Next.js", "React", "CSS", "AWS"],
    image: trip, // Replace this with the actual image import or path
  },
  {
    title: "SELL FEET PIC(E-Commerce Shop)",
    description:
      "An e-commerce platform that provides a seamless shopping experience for customers. It supports a wide range of products, secure payment gateways, and an easy-to-use admin panel for managing inventory.",
    technologies: ["Vue.js", "Firebase", "Stripe API"],
    image: ecom, // Replace this with the actual image import or path
  },
  {
    title: "TECHDOM(Social Media Agency)",
    description:
      "A dashboard for managing social media accounts, tracking performance metrics, and scheduling posts. It offers analytics, reports, and engagement tracking across multiple social media platforms.",
    technologies: ["React", "GraphQL", "Node JS", "GCP"],
    image: techDom, // Replace this with the actual image import or path
  },
  {
    title: "OTO(Fitness Tracker)",
    description:
      "A mobile and web application that helps users track their fitness goals, log workouts, monitor diet plans, and stay motivated with community challenges and personalized recommendations.",
    technologies: ["React Native", "Redux", "Node.js"],
    image: fitness, // Replace this with the actual image import or path
  },
];

const Home = () => {
  // const scrollRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    idea: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "contact"), formData);
      console.log("Document written with ID: ", docRef.id);
      // Clear the form after successful submission
      setFormData({
        fullName: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        idea: "",
      });
      alert("Form submitted successfully!");
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Failed to submit the form.");
    }
  };

  // useEffect(() => {
  //     const scrollContainer = scrollRef.current;

  //     const scrollContent = () => {
  //         // Scroll the container
  //         scrollContainer.scrollLeft += 1;

  // useEffect(() => {
  //     const scrollElement = scrollRef.current;
  //     let scrollInterval;

  //     const startScrolling = () => {
  //         scrollInterval = setInterval(() => {
  //             scrollElement.scrollLeft += 1; // Adjust scroll speed if needed
  //             if (scrollElement.scrollLeft >= scrollElement.scrollWidth / 2) {
  //                 scrollElement.scrollLeft = 0;
  //             }
  //         }, 20); // Adjust interval for smoother scroll
  //     };

  //     startScrolling();

  //     return () => {
  //         clearInterval(scrollInterval);
  //     };
  // }, []);

  const [selectedProject, setSelectedProject] = useState(projects[2]); // Default to the last project

  return (
    <div className="home" id="home">
      {/* <Header /> */}
      <div className="hero-section">
        <div className="flex flex-col pt-20 items-center">
          <div className="hero-content">
            <button className="accept-btn">Accepting New Projects</button>
            <h1>Transforming Ideas into Digital Success:</h1>
            <h1>An Innovative Solution</h1>
            <p>
              Join us in crafting customized solutions that drive your business
              forward.
            </p>
          </div>
        </div>
      </div>

      {/* Tech Image Scroller */}
      <TechScroller />

      <section className="services-section" id="services">
        <h2 className="section-title">A Dedicated Team of Innovators:</h2>
        <h2 className="section-title">
          Empowering Businesses with Cutting-Edge Solutions
        </h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="icon">
              {/* Add an appropriate icon */}
              <img src={vector1} alt="Tailored Solution" />
            </div>
            <h3>Custom Web Applications</h3>
            <p>
              We offer custom website design and development services that are
              perfectly aligned with your brand identity and business goals.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={vector3} alt="Comprehensive Services" />
            </div>
            <h3>Full-Stack Development</h3>
            <p>
              From front-end design to back-end architecture, our full-stack
              development services cover every aspect of web app creation,
              ensuring seamless integration and performance.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={vector2} alt="Mobile-Responsive Design" />
            </div>
            <h3>Responsive UI/UX Design</h3>
            <p>
              Our team crafts intuitive, mobile-responsive interfaces that
              enhance user experience, ensuring your web app looks and performs
              flawlessly on all devices.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={vector4} alt="Proven SEO Strategies" />
            </div>
            <h3>API Integration</h3>
            <p>
              We offer robust API integration services to connect your web app
              with third-party services, enhancing functionality and
              streamlining operations.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={vector6} alt="Expert App Development" />
            </div>
            <h3>Cloud-Based Solutions</h3>
            <p>
              Our cloud-based development services ensure that your web
              applications are scalable, reliable, and secure, with easy
              deployment and management.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={vector5} alt="Client-Centric Approach" />
            </div>
            <h3>Ongoing Support & Maintenance</h3>
            <p>
              We provide continuous support and maintenance to keep your web app
              up-to-date, secure, and running smoothly, ensuring long-term
              success.
            </p>
          </div>
        </div>
      </section>

      {/* <div className='image-service'>
                <img src={serviceCard} alt='#'></img>
            </div> */}
      {/* Our Projects Section */}
      <section className="portfolio-section" id="projects">
        <h2 className="section-title">
          A Portfolio of Our Accomplished Projects
        </h2>
        <button className="portfolio-button">Our Work</button>
        <div className="portfolio-container">
          <div className="project-list">
            {projects.map((project, index) => (
              <div
                key={index}
                className={`project-item ${
                  project.title === selectedProject.title ? "active" : ""
                }`}
                onClick={() => setSelectedProject(project)}
              >
                <span className="dot"></span>
                <h3>{project.title}</h3>
                {project.title === selectedProject.title && (
                  <p>{project.description}</p>
                )}
              </div>
            ))}
          </div>
          <div className="project-highlight">
            <img src={selectedProject.image} alt={selectedProject.title} />
            <div className="project-info">
              <h3>{selectedProject.title}</h3>
              <div className="technologies">
                {selectedProject.technologies.map((tech, index) => (
                  <span key={index} className="tech-icon">
                    {tech}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section" id="services">
        <h2 className="section-title">A Dedicated Team of Innovators:</h2>
        <h2 className="section-title">
          Product Managers, Engineers, and Designers
        </h2>
        <div className="services-grid">
          <div className="service-card">
            <div className="icon">
              {/* Add an appropriate icon */}
              <img src={business1} alt="Tailored Solution" />
            </div>
            <h3>Tailored Solution</h3>
            <p>
              We offer custom website design and development services that are
              perfectly aligned with your brand identity and business goals.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={business2} alt="Comprehensive Services" />
            </div>
            <h3>Comprehensive Services</h3>
            <p>
              From building visually stunning websites to developing powerful
              e-commerce platforms, managing content systems, and optimizing for
              search engines, we provide end-to-end digital services.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={business3} alt="Mobile-Responsive Design" />
            </div>
            <h3>Mobile-Responsive Design</h3>
            <p>
              Our mobile-responsive design services ensure that your site looks
              and functions flawlessly on all devices, providing a seamless
              experience for your users.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={business4} alt="Proven SEO Strategies" />
            </div>
            <h3>Proven SEO Strategies</h3>
            <p>
              Our SEO optimization services are designed to improve search
              engine rankings & drive targeted traffic to your site, helping
              reach more customers & grow your business.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={business5} alt="Expert App Development" />
            </div>
            <h3>Expert App Development</h3>
            <p>
              Our experienced development team can bring your ideas to life. We
              build apps that are not only functional and user-friendly but also
              scalable and secure, meeting the demands of your business.
            </p>
          </div>
          <div className="service-card">
            <div className="icon">
              <img src={business6} alt="Client-Centric Approach" />
            </div>
            <h3>Client-Centric Approach</h3>
            <p>
              Your success is our success. We prioritize clear communication,
              transparency, and collaboration throughout every project.
            </p>
          </div>
        </div>
      </section>

      <ImapctSection />

      <div className="clients-section">
        <button className="clients-button">Our Clients</button>
        <p className="accel-heading">
          Worked with Startup funded by Accel India & Ycombinator
        </p>
        <h3 className="trusted-clients-title">Trusted Clients</h3>
        <ClientScroller />
      </div>
      {/* Testimonials Section */}
      {/* <section className="testimonials-section" id="testimonials">
                <h2 className="section-title">What Others Say About</h2>
                <h3 className="section-title">our Services</h3>
                <div className="testimonials-container">
                    <div className="testimonial-card">
                        <p>{testimonials[currentIndex].text}</p>
                        <div className="testimonial-author">
                            <img src={testi1} alt={testimonials[currentIndex].author} />
                            <div>
                                <h4>{testimonials[currentIndex].author}</h4>
                                <p>{testimonials[currentIndex].title}</p>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div className="dots">
                    {testimonials.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => setCurrentIndex(index)}
                        ></span>
                    ))}
                </div>
            </section> */}
      <Testimonials />

      {/* how does it work */}
      <section className="how-it-works-section" id="how-it-works">
        <h2 className="section-title">How does it work?</h2>
        <button className="connect-button">Connect with Us</button>
        <div className="how-it-works-container">
          <div className="steps">
            <div className="step">
              <div className="step-number">1.</div>
              <div className="step-content">
                <h3>Intro Call</h3>
                <p>
                  During a 30-minute meeting, our domain expert dives into your
                  business and describes the steps for future collaboration.
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">2.</div>
              <div className="step-content">
                <h3>Discovery Call</h3>
                <p>
                  Together with you, our technical team defines the user flow,
                  feature list, and project risks.
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">3.</div>
              <div className="step-content">
                <h3>Project Planning</h3>
                <p>
                  We provide the implementation plan, timelines, and estimations
                  for your project.
                </p>
              </div>
            </div>
          </div>
          <div className="contact-form" id="contact">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Full name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  placeholder="Company Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <input
                  type="tel"
                  placeholder="Phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <textarea
                placeholder="Describe your idea"
                name="idea"
                value={formData.idea}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
