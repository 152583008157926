import React, { useState, useEffect,useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { WindowView, SmoothScroll } from './ViewPort';
import logo from "../Assets/TheTechBusLogo.png"
import "./Header.css"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { y_top } = WindowView();
  const { pathname } = useLocation();
  const timerRef = useRef(0);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Navi with width 800px
  useEffect(() => {
    const links = document.querySelectorAll('.link-con .navi-link');

    if (pathname !== '/') {
      timerRef.current = 100;
    }

    // Toggle link active
    links.forEach((link) => {
      const handleClick = (e) => {
        const targetId = link.getAttribute('name');
        const duration = 1000;
        if (targetId) {
          e.preventDefault();
          setTimeout(() => {
            SmoothScroll({ targetId, duration });
            timerRef.current = 0;
          }, timerRef.current);
        }
      };

      link.addEventListener('click', handleClick);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', () => {});
      });
    };
  }, [pathname]);

  // Toggle nav background color
  useEffect(() => {
    const navigation = document.querySelector('nav');

    if (y_top > 0) {
      navigation.classList.add('bg-light');
    } else {
      navigation.classList.remove('bg-light');
    }
  }, [y_top]);
  return (
    <nav className="shadow-md w-full header" >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* <div className="flex-shrink-0 flex items-center"> */}
            {/* <span className="text-black text-xl font-bold"> */}
              <img className='mt-2' src={logo}  alt='/'/>
              {/* </span> */}
          {/* </div> */}
          <div className="flex md:hidden items-center">
            <button
              onClick={toggleMenu}
              className="text-gray-800 hover:text-black focus:outline-none"
            >
              <svg
                className="h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex space-x-8 items-center">
            
            <Link
              to="#home"
              className="text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'home', duration: 1000 });
              }}
            >
              Home
            </Link>
            <Link
              to="#services"
              className="text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'services', duration: 1000 });
              }}
            >
              Services
            </Link>
            <Link
              to="#projects"
              className="text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'projects', duration: 1000 });
              }}
            >
              Projects
            </Link>
            <Link
              to="#about"
              className="text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'about', duration: 1000 });
              }}
            >
              About
            </Link>
            <Link
              to="#contact"
              className="text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'contact', duration: 1000 });
              }}
            >
              Contact
            </Link>
          </div>
          <div className="hidden md:flex items-center">
            <button
              className="bg-black text-white px-4 py-2 rounded-lg flex items-center"
              onClick={() =>
                window.open('https://calendly.com/thetechbus-support/30min', '_blank')
              }
            >
              Book a Free Demo
              <span className="ml-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="10px"
                  width="30"
                  height="22"
                  viewBox="0 0 48 48"
                >
                  <rect
                    width="16"
                    height="16"
                    x="12"
                    y="16"
                    fill="#fff"
                    transform="rotate(-90 20 24)"
                  ></rect>
                  <polygon
                    fill="#1e88e5"
                    points="3,17 3,31 8,32 13,31 13,17 8,16"
                  ></polygon>
                  <path
                    fill="#4caf50"
                    d="M37,24v14c0,1.657-1.343,3-3,3H13l-1-5l1-5h14v-7l5-1L37,24z"
                  ></path>
                  <path
                    fill="#fbc02d"
                    d="M37,10v14H27v-7H13l-1-5l1-5h21C35.657,7,37,8.343,37,10z"
                  ></path>
                  <path
                    fill="#1565c0"
                    d="M13,31v10H6c-1.657,0-3-1.343-3-3v-7H13z"
                  ></path>
                  <polygon
                    fill="#e53935"
                    points="13,7 13,17 3,17"
                  ></polygon>
                  <polygon
                    fill="#2e7d32"
                    points="38,24 37,32.45 27,24 37,15.55"
                  ></polygon>
                  <path
                    fill="#4caf50"
                    d="M46,10.11v27.78c0,0.84-0.98,1.31-1.63,0.78L37,32.45v-16.9l7.37-6.22C45.02,8.8,46,9.27,46,10.11z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      {isOpen && (
        <div className="md:hidden mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white">
            <Link
              to="/#services"
              className="navi-link block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              name="services"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'services', duration: 1000 });
              }}
            >
              Services
            </Link>
            <Link
              to="#home"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'home', duration: 1000 });
              }}
            >
              Home
            </Link>
            <Link
              to="#services"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'services', duration: 1000 });
              }}
            >
              Services
            </Link>
            <Link
              to="#projects"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'projects', duration: 1000 });
              }}
            >
              Projects
            </Link>
            <Link
              to="#about"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'about', duration: 1000 });
              }}
            >
              About
            </Link>
            <Link
              to="#contact"
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-800 hover:text-black"
              onClick={(e) => {
                e.preventDefault();
                SmoothScroll({ targetId: 'contact', duration: 1000 });
              }}
            >
              Contact
            </Link>
            <a
              href="#contact"
              className="block px-3 py-2 mt-4 bg-black text-white rounded-lg text-center flex justify-center"
              onClick={(e) => {
                e.preventDefault();
                window.open('https://calendly.com/thetechbus-support/30min', '_blank');
              }}
            >
              Book a Free Demo
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="10px"
                width="30"
                height="22"
                viewBox="0 0 48 48"
                className="ml-6"
              >
                <rect
                  width="16"
                  height="16"
                  x="12"
                  y="16"
                  fill="#fff"
                  transform="rotate(-90 20 24)"
                ></rect>
                <polygon
                  fill="#1e88e5"
                  points="3,17 3,31 8,32 13,31 13,17 8,16"
                ></polygon>
                <path
                  fill="#4caf50"
                  d="M37,24v14c0,1.657-1.343,3-3,3H13l-1-5l1-5h14v-7l5-1L37,24z"
                ></path>
                <path
                  fill="#fbc02d"
                  d="M37,10v14H27v-7H13l-1-5l1-5h21C35.657,7,37,8.343,37,10z"
                ></path>
                <path
                  fill="#1565c0"
                  d="M13,31v10H6c-1.657,0-3-1.343-3-3v-7H13z"
                ></path>
                <polygon
                  fill="#e53935"
                  points="13,7 13,17 3,17"
                ></polygon>
                <polygon
                  fill="#2e7d32"
                  points="38,24 37,32.45 27,24 37,15.55"
                ></polygon>
                <path
                  fill="#4caf50"
                  d="M46,10.11v27.78c0,0.84-0.98,1.31-1.63,0.78L37,32.45v-16.9l7.37-6.22C45.02,8.8,46,9.27,46,10.11z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
